import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";

import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import SplashScreen from "./modules/splashscreen";
import "./translation/i18n";
const App = lazy(
  () =>
    new Promise((resolve) => {
      resolve(import("./modules/App"));
    })
);
ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<SplashScreen />}>
      <App />
    </Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
