export const BATCH_EN = {
	Name: 'Name',
	Date: 'Date',
	Phase: 'Phase',
	'Batch Name': 'Batch Name',
	'Batch ID': 'Batch ID',
	'Farm ID': 'Farm ID',
	'Show Details': 'Show Details',
	'Basic Details': 'Basic Details',
	Preview: 'Preview',
	'Nursery Practises': 'Nursery Practises',
	'Land Preparation': 'Land Preparation',
	'Seedling Transplantaion': 'Seedling Transplantaion',
	Harvesting: 'Harvesting',
	Storage: 'Storage',
	Distribution: 'Distribution',
	'Product Activities (Phases)': 'Product Activities (Phases)',
	'Product Details': 'Product Details',

	Plot: 'Plot',
	'Batch Detail': 'Batch Detail',
	'Batch Info': 'Batch Info',
	'Advanced Info': 'Advanced Info',
	'Stopped Phase': 'Stopped Phase',
	Sold: 'Sold',
	Removed: 'Remove',
	Damaged: 'Damaged',
	Others: 'Others',
	'Archive Confirm Text': 'Are you sure you want to archive this batch?',
	'Unarchive Confirm Text': 'Are you sure you want to unarchive this batch?',
	Confirm: 'Confirm',
	Yes: 'Yes',
	Cancel: 'Cancel',
	'Archive Reason': 'Archive Reason'
};
