export const COMMON_EN = {
	'My Farm': 'My Farm',
	Batches: 'My Batches',
	Products: 'My Products',
	ProductInfo: 'Product Info',
	Description: 'Description',
	'No products added yet': 'No products added yet',
	Product: 'Product',
	weather: 'weather',
	Settings: 'Settings',
	form: {
		Submit: 'Submit',
		Save: 'Save',
		Cancel: 'Cancel'
	},
	productCategory: {
		Flora: 'Crop',
		Fauna: 'Livestock',
		Plant: 'Plant',
		Cereal: 'Cereal',
		Legumes: 'Legumes',
		Vegetables: 'Vegetables',
		Vegetable: 'Vegetable',

		Fruit: 'Fruit',
		'Oil Seed': 'Oil Seed',
		Beverage: 'Beverage',
		'Small Ruminants': 'Small Ruminants',
		'Large Ruminants': 'Large Ruminants',
		'Large Ruminant': 'Large Ruminant',

		'Feed and forage': 'Feed and forage',
		Fibre: 'Fibre',
		Ornamental: 'Ornamental',
		Others: 'Others',
		Animal: 'Animal',
		Swine: 'Swine',
		Poultry: 'Poultry',
		Equines: 'Equines',
		Camels: 'Camels',
		Fishes: 'Fishes',
		Insects: 'Insects',
		'No info': 'No info',
		'No products': 'No products'
	}
};
