import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

function SplashScreen({ message }) {
	return (
		<div className="container vh-100 d-flex align-items-center justify-content-center ">
			<Spinner animation="border" variant="success" />
			{/* <Spinner animation="grow" /> */}
		</div>
	);
}

export default SplashScreen;
