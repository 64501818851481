export const PRODUCT_EN = {
	Maize: 'Maize',
	detailLabel: {
		'Planting Period': 'Planting Period',
		'Maturity Period': 'Maturity Period',
		'Seedling Preparation Time': 'Seedling Preparation Time',
		'Harvesting Period': 'Harvesting Period',
		'Seed Rate Per Kattha (gm)': 'Seed Rate Per Kattha (gm)',
		'Seed Rate Per Ropani (gms)': 'Seed Rate Per Ropani (gms)',
		'Distance Between Plants (cms)': 'Distance Between Plants (cms)',
		'Distance Between Rows (cms)': 'Distance Between Rows (cms)',
		'Production Capacity': 'Production Capacity'
	}
};
