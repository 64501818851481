export const COMMON_NP = {
	'My Crops': 'मेरो बालीहरु',
	Batches: 'मेरो ब्याचहरु',
	Products: 'मेरो उत्पादनहरु',
	ProductInfo: 'उत्पादन विवरण',
	Description: 'विवरण',
	'No batch added': 'कुनै उत्पादनहरु थपिएको छैन',
	Product: 'उत्पादन',
	weather: 'मौसम',
	Settings: 'सेटिंग्स',
	form: {
		Submit: 'बुझाउनु हाेस्',
		Save: 'भण्डार गर्नुहोस्',
		Cancel: 'रद्द गर्नुहोस्'
	},
	productCategory: {
		Flora: 'बाली',
		Fauna: 'पशुपालन',
		Plant: 'बिरुवा',
		Cereal: 'अन्न बाली',
		Legumes: 'दलहन बाली',
		Vegetables: 'तरकारी',
		Vegetable: 'तरकारी',

		Fruit: 'फलफूल',
		'Oil Seed': 'तेलको बिउ',
		Beverage: 'पेय पदार्थ',
		'Feed and forage': 'खाना र चारा',
		Fibre: 'रेशादार',
		Ornamental: 'सजावटी',
		Others: 'अरु',
		'Small Ruminants': 'सानो रोमिनेन्ट',
		'Large Ruminants': 'ठूलो  रोमिनेन्ट',
		'Large Ruminant': 'ठूलो  रोमिनेन्ट',

		Animal: 'पशुपालन',
		Swine: 'सुँगुरहरु',
		Poultry: 'कुखुराहरु',
		Equines: 'बराबर',
		Camels: 'ऊँटहरु',
		Fishes: 'माछाहरु',
		Insects: 'किराहरु',
		'No info': 'विवरण छैन ',
		'No products': 'उत्पादन छैन'
	}
};
