export const PROFILE_EN = {
	Profile: 'Profile',
	'User Name': 'User Name',
	'Upload Image': 'Upload Image',
	'My Info': 'My Info',
	'My Farm': 'My Farm',
	'User Info': 'User Info',
	Info: 'Info',
	'Farm Info': 'Farm Info',
	'Basic Info': 'Basic Info',
	'Your name': 'Your name',
	'Your phone number': 'Your phone number',
	'Your email address': 'Your email address',
	'Select your gender': 'Select your gender',
	Male: 'Male',
	Female: 'Female',
	Others: 'Others',
	Search: 'Search',
	'Your Name': 'Your Name',
	'Farm Area': 'Farm Area',
	'Name of farm-owner': 'Name of farm-owner',
	'Farm owner contact number': 'Farm owner contact number',
	Latitude: 'Latitude',
	Longitude: 'Longitude',
	'Prefered Language': 'Prefered Language',
	'Select Prefered Language': 'Select Prefered Language',
	'Take another photo': 'Take another photo',
	Nepali: 'Nepali',
	English: 'English',
	'Batch Archives': 'Batch Archives',
	Logout: 'Logout'
};
