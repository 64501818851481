export const PROFILE_NP = {
	Profile: 'प्रोफाइल',
	'User Name': 'प्रयोगकर्ताको नाम',
	'Upload Image': 'तस्विर थप्नुहोस् ',
	'My Info': 'मेरो विवरण ',
	'My Farm': 'मेरो खेतिबारी ',
	'User Info': 'प्रयोगकर्ताको विवरण',
	'Farm Info': 'खेतिबारीको विवरण',
	'Basic Info': 'आधारभूत विवरण',
	'Your name': 'तपाईंको नाम',
	'Your phone number': 'तपाईंको मोबाईल नम्बर ',
	'Your email address': 'तपाईंको ईमेल ठेगाना',
	'Select your gender': 'तपाईंको लिङ्ग छन्नुहोस् ',
	Male: 'पुरुष',
	Female: 'महिला',
	Others: 'अरु',
	Search: 'खोजी गर्नुहोस्',
	'Your Name': 'तपाईंको नाम',
	'Farm Area': 'खेतिबारी गर्ने ठाउँ',
	'Name of farm-owner': 'जग्गा धनीको नाम',
	'Farm owner contact number': 'जग्गा धनीको सम्पर्क नम्बर ',
	Latitude: 'अक्षांश',
	Longitude: 'देशान्तर',
	Info: 'विवरण',
	'Prefered Language': 'रुचाइएको भाषा',
	'Select Prefered Language': 'रुचाइएको भाषा छान्नुहोस्',
	'Take another photo': 'अर्को फोटो लिनुहोस ्',
	'Batch Archives': 'पुराना बाली रेकर्ड',
	Logout: 'लगआउत '
};
