export const SETTING_EN = {
	'IPFS upload URL': 'IPFS upload URL',
	'Enter full IPFS url': 'enter full IPFS url',
	'IPFS download url': 'IPFS download url',
	'Enter IPFS download url': 'Enter IPFS download url',
	'Connect with Google': 'Connect with Google',
	'Connect to Google Drive': 'Connect to Google Drive',
	'Continue with your Google Account': 'Continue with your Google Account',
	'Please choose Google account. Please click the switch account button to change account':
		'Please choose Google account. Please click the switch account button to change account',
	'Loading User…': 'Loading User…',
	'Current Network': 'Current Network',
	Networks: 'Networks',
	'Select an Network': 'Select an Network',
	'Please note: changing network will show current balance of only the active network':
		'Please note: changing network will show current balance of only the active network',
	'Available Networks': 'Available Networks',
	'Rumsan Network': 'Rumsan Network',
	'Rumsan Test Network': 'Rumsan Test Network',
	'Custom Network': 'Custom Network',
	'Network updated successfully': 'Network updated successfully'
};
